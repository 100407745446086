import { ISODateString } from "@smartrr/shared/entities/ISODateString";

export const isValidDate = (date?: string): boolean => {
  if (!date) {
    return false;
  }

  const [month, day, year] = date.split("/");

  if (!month || !day || !year) {
    return false;
  }

  if (+month < 0 || +month > 12 || +day > 31 || +day < 0 || +year > new Date().getFullYear() || +year < 1900) {
    return false;
  }

  try {
    const _ = new Date(+year, +month - 1, +day);
    return true;
  } catch {
    return false;
  }
};

export const getAgeFromBirthdayString = (dateStr?: string): number => {
  const date = new Date(dateStr ?? "");
  if (date.getTime() >= Date.now()) {
    return 0;
  }
  return new Date(Date.now() - date.getTime()).getFullYear() - 1970;
};

export const getMonthFirstFormattedDate = (date: Date | string | number) => {
  if (date instanceof Date) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }
  const dateFromString = new Date(date);
  return `${dateFromString.getMonth() + 1}/${dateFromString.getDate()}/${dateFromString.getFullYear()}`;
};

export const formatMonthFirstDateToTimestamp = (date: string | number) => {
  return new Date(date).getTime();
};

export const dateParser = (ISODate: string, billingTime: string): string => {
  if (!ISODate) {
    return "";
  }
  const date = ISODateString.fromString(ISODate);
  return date.setZone(billingTime).toLocaleString(
    {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    {
      locale: "en-US",
    }
  );
};
